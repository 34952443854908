@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-dark-200 text-gray-100;
  }
}

@layer components {
  .btn-primary {
    @apply px-4 py-2 bg-purple-primary hover:bg-purple-dark text-white rounded-md 
    transition-all duration-200 ease-in-out transform hover:scale-105 
    focus:outline-none focus:ring-2 focus:ring-purple-light focus:ring-opacity-50;
  }

  .input-dark {
    @apply bg-dark-100 border border-dark-300 text-gray-100 
    focus:border-purple-primary focus:ring-1 focus:ring-purple-primary focus:ring-opacity-50
    rounded-md shadow-sm w-full px-2 sm:px-3 py-1.5 sm:py-2
    transition-colors duration-200
    placeholder-gray-500
    hover:border-purple-primary/50;
  }

  select.input-dark {
    @apply appearance-none bg-dark-100;
  }

  textarea.input-dark {
    @apply resize-none;
  }

  .input-dark:hover {
    @apply border-purple-primary/50;
  }

  .input-dark:focus {
    @apply outline-none border-purple-primary;
  }

  .checkbox-dark {
    @apply form-checkbox h-4 w-4 bg-dark-300 border-dark-300 
    text-purple-primary rounded
    focus:ring-purple-primary focus:ring-opacity-50
    hover:border-purple-primary/50
    transition-colors duration-200;
  }

  .card-dark {
    @apply bg-dark-100 rounded-lg shadow-lg
    transition-all duration-300 
    border border-dark-300/50
    hover:border-purple-primary/50
    hover:shadow-purple-primary/20 hover:shadow-lg;
  }

  .modal-dark {
    @apply fixed inset-0 bg-black/70 
    flex items-center justify-center 
    animate-fade-in backdrop-blur-sm
    p-2 sm:p-4;
  }

  .modal-content-dark {
    @apply bg-dark-100 rounded-lg p-6 
    w-full max-w-md 
    border border-purple-primary/20
    shadow-xl shadow-purple-primary/10
    animate-slide-up;
  }

  .modal-content-dark-wide {
    @apply bg-dark-100 rounded-lg p-4 sm:p-6 
    w-full max-w-5xl 
    border border-purple-primary/20
    shadow-xl shadow-purple-primary/10
    animate-slide-up
    max-h-[90vh]
    overflow-y-auto;
  }

  .modal-content-dark-wide::-webkit-scrollbar {
    @apply w-2;
  }

  .modal-content-dark-wide::-webkit-scrollbar-track {
    @apply bg-dark-300 rounded-full;
  }

  .modal-content-dark-wide::-webkit-scrollbar-thumb {
    @apply bg-purple-primary/50 rounded-full
    hover:bg-purple-primary/70
    transition-colors duration-200;
  }
}
